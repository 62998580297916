import React, {DetailedHTMLProps, ReactNode} from 'react';

import {CoverContainer} from './index.style';

export interface IContainerProps extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    myHeight?: number;
    children: ReactNode;
    id: string;
  }

const Container: React.FC<IContainerProps> = ({children, myHeight, id, ...rest}) => {

    return (
        <CoverContainer myHeight={myHeight || 100} id={id} style={rest.style} className={rest.className}>
            {children}
        </CoverContainer>
    );
};

export default Container;
