import styled from 'styled-components';

import {colors} from '../../utils/colors';

export const Border = styled.div`
  width: 80px;
  height: 2px;
  margin: 10px auto 30px;
  background-color: ${colors.primary};
`;
