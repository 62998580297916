import {MdArrowForward} from 'react-icons/md';
import styled, {keyframes} from 'styled-components';

interface ICoverContainer {myHeight: number}

export const CoverContainer = styled.div<ICoverContainer>`
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-height: ${props => `calc(${props.myHeight}vh - 70px)`};
  position: relative;
  z-index: 1;
  margin-top: 70px;
`;

export const CoverBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

`;

export const CoverContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CoverBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const Images = styled.div<{ image: string }>`
  transition: background-image 1s ease-in-out;
  background-image: url(${(props) => props.image});
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-clip: content-box;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%
`;

const textAnimation = keyframes`
  0% {margin-top: 0;}
  10% {margin-top: 0;}
  20% {margin-top: -5.62rem;}
  30% {margin-top: -5.62rem;}
  40% {margin-top: -11.24rem;}
  60% {margin-top: -11.24rem;}
  70% {margin-top: -5.62rem;}
  80% {margin-top: -5.62rem;}
  90% {margin-top: 0;}
  100% {margin-top: 0;}
`;

export const TextMain = styled.main`
  color: #e5e5e5;
  font-size: 2.26rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SectionAnimate = styled.section`
  height: 50px;
  overflow: hidden;
  text-align: center;
  div:first-child {
    animation: ${textAnimation} 12s infinite;
  }
  > div > span {
    padding: 0.25rem 0.75rem;
    height: 2.81rem;
    margin-bottom: 2.83rem;
    display: inline-block;
    text-shadow: 2px 2px 4px black;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.8rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.6rem;
  }
`;
