import {Border} from './Border';
import Container from './Container';
import {Heading} from './Heading';
import {SubHeading} from './SubHeading';
import {TextWrapper} from './TextWrapper';

export {
    TextWrapper,
    Container,
    Heading,
    Border,
    SubHeading
};
