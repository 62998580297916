import styled from 'styled-components';

import { colors } from '../../utils/colors';

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const LinkPress = styled.a`
  color: ${colors.primaryGreen};
  font-weight: 300;
  text-decoration: none;
  transition: opacity 0.3s;
  margin-bottom: 12px;
  font-size: 22px;

  &:hover {
    opacity: 0.3;
  }
`;

const Description = styled.h3`
  color: #424242;
  margin: 0 0 10px;
  font-weight: 300;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.1px;
`;

const Image = styled.div`
  margin-left: 10px;
`;

export default { LinkContainer, LinkPress, Description, Image };