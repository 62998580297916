import styled from 'styled-components';

export const Heading = styled.h1`
  color: #060606;
  font-style: normal;
  letter-spacing: 1px;
  font-size: 50px;
  font-weight: 400;
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
  text-align: center;
`;
