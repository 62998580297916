import React from 'react';
import Image from 'next/image';

import { Border,Container, Heading, SubHeading, TextWrapper } from '../../components';

import S from './index.style';

const About = () => {
    return (
        <Container id="about">
            <TextWrapper>
                <Heading>О Нас</Heading>
                <Border />
                <Image
                    src="/assets/main/4.webp"
                    alt="Picture of the author"
                    width={209}
                    height={141}
                    className={S.Image}
                />
                <SubHeading style={{ fontWeight: '500' }}>
                    БЮРО ПО ДИЗАЙНУ ИНТЕРЬЕРА
                </SubHeading>
                <SubHeading style={{ fontWeight: '400' }}>
                    ИНДИВИДУАЛЬНОЕ ПЛАНИРОВАНИЕ ПРОСТРАНСТВА ДЛЯ ЧАСТНОГО И КОММЕРЧЕСКОГО ИСПОЛЬЗОВАНИЯ
                </SubHeading>
                <SubHeading>МЫ ДОСТУПНЫ ДЛЯ ВАС</SubHeading>
                <S.LinkContainer>
                    <S.LinkPress href="tel:+375296565168">
                        +375 29 656 51 68
                    </S.LinkPress>
                    <S.LinkPress href="tel:+375296369804">
                        +375 29 636 98 04
                    </S.LinkPress>
                    <S.LinkPress href="mailto:grafit2001@gmail.com">
                        grafit2001@gmail.com
                    </S.LinkPress>
                    <S.LinkPress
                        href="https://www.google.com/maps/place/%D0%93%D0%A0%D0%90%D0%A4%D0%98%D0%A2+-+%D0%91%D0%AE%D0%A0%D0%9E+%D0%9F%D0%9E+%D0%94%D0%98%D0%97%D0%90%D0%99%D0%9D%D0%A3+%D0%98%D0%9D%D0%A2%D0%95%D0%A0%D0%AC%D0%95%D0%A0%D0%90/@53.1331756,29.2229112,19z/data=!3m1!4b1!4m6!3m5!1s0x46d731505246fb7b:0xda532ddd975ce700!8m2!3d53.1331748!4d29.2234584!16s%2Fg%2F11ss9qtblm"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        ул. К. Маркса, 78/122, г. Бобруйск, Беларусь
                    </S.LinkPress>
                </S.LinkContainer>
                <S.Description>
                    Наш многолетний опыт и солидные специальные знания делают нас компетентным партнером во всем, что касается интерьеров.
                </S.Description>
                <S.Description>
                    Мы создаем неповторимые интерьеры с неповторимой атмосферой.
                </S.Description>
                <S.Description>
                    С нашей страстью к неординарным решениям для помещений и бескомпромиссным пониманием качества, мы создаем функциональные решения.
                </S.Description>
                <S.Description>
                    Интерьеры, которые не оставят Вас равнодушными.
                </S.Description>
            </TextWrapper>
        </Container>
    );
};

export default About;