interface IColors {
    primary: string,
    accent: string,
    background: string,
    text: string,
    placeholder: string,
    disabled: string,
    border:string,
    primaryGreen: string,
    tooltip: string,
    error: string,
    gray100: string,
    gray200:string,
    gray300: string,
    gray400: string,
    gray500: string,
    gray600: string,
    gray700: string,
    green1: string,
    gray800: string,
}
export const colors: IColors = {
    primary: '#009FBC',
    accent: '#1ABDB5',
    background: '#FFFFFF',
    text: '#232323',
    placeholder: '#7D7D7D',
    disabled: '#E1E1E1',
    border: '#F5F5F5',
    primaryGreen: '#005365',
    tooltip: '#005868',
    error: '#EE4E4E',
    gray100: '#F8F8F8',
    gray200: '#F1F1F1',
    gray300: '#E1E1E1',
    gray400: '#ADADAD',
    gray500: '#919191',
    gray600: '#4B4B4B',
    gray700: '#232323',
    green1: '#6392AD',
    gray800: '#222B36'
};
