import styled from 'styled-components';

export const SubHeading = styled.h2<{whiteText?: boolean}>`
  color: ${props => props.whiteText ? 'white' : '#424242'} ;
  margin: 0 0 20px;
  font-weight: 300;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.2px;
  font-style: normal;

  @media screen and (max-width: 480px) {
    font-size: 19px;
    margin: 0 0 20px;
  }
`;
